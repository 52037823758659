import React from "react"
import pepe from "./gifs/pepe-coffee.gif"

export default function Navbar() {
  return (
    <nav className="flexbox">
      <div className="title-flexbox">
        <img src={pepe} alt="Coffee streicheln" className="nav-logo"/>
        <h1>Brühm League</h1>
      </div>
      <ul className="hor">
        <li>Brühm</li>
        <li>Bitcoins</li>
        <li>Bastelzubehör</li>
      </ul>
    </nav>
  )
}