import React from 'react';
import ReactDOM from 'react-dom/client';
import Navbar from './Navbar';
import IntroVideo from './components/IntroVideo';
import './index.css';


function MainContent() {
  return (
    <div className='main'>
      <h1>Brühm League Merchandise - Brühm League Trunks, T-Shirts und Bitcoins</h1>
      <IntroVideo embedId='Irhw-ju-bew' />
    </div>
  )
}


function Footer() {
  return (
    <footer className='footer'>
      © 2044 Femboy Factories. No rights reserved.
    </footer>
  )
}
function Page () {
  return (
    <div className='mainPage'>
      <div className='headerContent'>
        <Navbar />
        <MainContent />
      </div>
      <Footer />
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Page />
);